import React, { useEffect, useState } from "react";
import ApiLoader from "../../common/ApiLoader";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Link from "@material-ui/core/Link";
import Loading from "../../common/Loading";
import { AppHeaderComponent, PermissionsApi, useAuth } from "@unity/components";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { Typography } from "@mui/material";

export default function PermissionsPage({ route, context, historys }) {
  const { checkPermission } = useAuth();

  const [data, setData] = useState(false);
  const [loading, setLoading] = useState({ state: false });

  let history = useHistory();

  const handleSubmit = async () => {
    setLoading({ status: true, data: "Saving Permissions please wait..." });

    const x = { role: route.match.params.id, access: {} };

    Object.keys(data).map((name, i) => {
      Object.keys(data[name]).map((mod, i) => {
        x.access[mod] = data[name][mod];
      });
    });

    const postTemplate = await PermissionsApi.savePermissions(x);

    setLoading({ status: true, data: "Save Success !" });

    setTimeout(() => {
      history.push("/setting/roles");
    });
  };

  const handleChange = (name, objName, type, e) => {
    const newData = { ...data };
    newData[name][objName][type] = e.target.checked;
    setData(newData);
  };

  useEffect(() => {
    const getPermissions = async () => {
      const res = await PermissionsApi.getPermissions(route.match.params.id);
      setData(res.data);
    };
    getPermissions();
  }, []);

  if (data) {
    return (
      <div>
        <ApiLoader status={loading.status} data={loading.data} />

        <AppHeaderComponent
          name="settings"
          saveBtn={
            checkPermission("update_permission_admin") ? handleSubmit : null
          }
          theme={context.theme}
          context={context}
        />
        <div style={{ paddingTop: 140 }}>
          <div className="page-section padding-lg">
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Module</TableCell>
                    <TableCell align="right">Create</TableCell>
                    <TableCell align="right">Read</TableCell>
                    <TableCell align="right">Read All</TableCell>
                    <TableCell align="right">Update</TableCell>
                    <TableCell align="right">Delete</TableCell>
                    <TableCell align="right">Module Admin</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(data).map((name, i) => {
                    var objName;
                    Object.keys(data[name]).map((mod, i) => {
                      objName = mod;
                    });
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {true ? (
                            <Link
                              component={RouterLink}
                              to={`/settings/roles/${route.match.params.id}/permissions/actions/${objName}`}
                            >
                              {objName}
                            </Link>
                          ) : (
                            <Typography>{objName}</Typography>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            defaultChecked={data[name][objName]["C"]}
                            color="primary"
                            onChange={(e) =>
                              handleChange(name, objName, "C", e)
                            }
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            defaultChecked={data[name][objName]["R"]}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            onChange={(e) =>
                              handleChange(name, objName, "R", e)
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            defaultChecked={data[name][objName]["RA"]}
                            color="primary"
                            onChange={(e) =>
                              handleChange(name, objName, "RA", e)
                            }
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            defaultChecked={data[name][objName]["U"]}
                            color="primary"
                            onChange={(e) =>
                              handleChange(name, objName, "U", e)
                            }
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            defaultChecked={data[name][objName]["D"]}
                            color="primary"
                            onChange={(e) =>
                              handleChange(name, objName, "D", e)
                            }
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            defaultChecked={data[name][objName]["MA"]}
                            color="primary"
                            onChange={(e) =>
                              handleChange(name, objName, "MA", e)
                            }
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}

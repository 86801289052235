import React from "react";
import { TextField } from "@mui/material";
import { AppHeaderComponent, ThemeApi, useAuth } from "@unity/components";

export default function Theme({ route, context, history }) {
  const { checkPermission } = useAuth();

  const sideMenu = (e, element) => {
    if (e) {
      context.setTheme({
        ...context.theme,
        sidebar: { ...context.theme.sidebar, [element]: e },
      });
    }
  };

  const topMenu = (e, element) => {
    if (e) {
      context.setTheme({
        ...context.theme,
        top_menu: { ...context.theme.top_menu, [element]: e },
      });
    }
  };

  const handleSave = async () => {
    const test = await ThemeApi.saveTheme({ theme: context.theme });
  };

  return (
    <div>
      <AppHeaderComponent
        name="settings"
        theme={context.theme}
        saveBtn={checkPermission("update_appearance_admin") ? handleSave : null}
        context={context}
      />
      <div style={{ paddingTop: 120 }}>
        <div className="block">
          <div className="container-fluid">
            <h3>Side Menu</h3>
            <div className="row">
              <div className="col-lg-3">
                <p>Background</p>
                <TextField
                  type="color"
                  name="color"
                  defaultValue={context.theme.secondary}
                  onChange={(color) => sideMenu(color, "background")}
                />
              </div>

              <div className="col-lg-3">
                <p>Active Link Background</p>
                <TextField
                  type="color"
                  name="color"
                  defaultValue={context.theme.secondary}
                  onChange={(color) => sideMenu(color, "active_background")}
                />
              </div>

              <div className="col-lg-3">
                <p>Active Link Font Colour</p>
                <TextField
                  name="color"
                  type="color"
                  defaultValue={context.theme.secondary}
                  onChange={(color) => sideMenu(color, "active_font")}
                />
              </div>

              <div className="col-lg-3">
                <p>Font Colour</p>
                <TextField
                  name="color"
                  type="color"
                  defaultValue={context.theme.secondary}
                  onChange={(color) => sideMenu(color, "font")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="block">
          <div className="container-fluid">
            <h3>Top Menu</h3>

            <div className="row">
              <div className="col-lg-3">
                <p>Background</p>
                <TextField
                  name="color"
                  type="color"
                  defaultValue={context.theme.secondary}
                  onChange={(color) => topMenu(color, "background")}
                />
              </div>

              <div className="col-lg-3">
                <p>Font Colour</p>
                <TextField
                  name="color"
                  type="color"
                  defaultValue={context.theme.secondary}
                  onChange={(color) => topMenu(color, "font")}
                />
              </div>
              <div className="col-lg-3">
                <p>Button Background Colour</p>
                <TextField
                  name="color"
                  type="color"
                  defaultValue={context.theme.secondary}
                  onChange={(color) => topMenu(color, "button_background")}
                />
              </div>
              <div className="col-lg-3">
                <p>Button Font Colour</p>
                <TextField
                  name="color"
                  type="color"
                  defaultValue={context.theme.secondary}
                  onChange={(color) => topMenu(color, "button_font")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

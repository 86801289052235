import React from "react";
import {
  IoIosColorPalette,
  IoIosPeople,
  IoIosArrowRoundForward,
} from "react-icons/io";
import MailIcon from "@material-ui/icons/Mail";
import TuneIcon from "@mui/icons-material/Tune";
import { Link } from "react-router-dom";

import { AppHeaderComponent, useAuth } from "@unity/components";
import config from "../config";

export default function Settings({ context }) {
  const marketingAppUuid = "76635ee3-bb89-4ff4-86aa-b4dfae0887b4";
  const sandboxAppUuid = "ff0eaf73-93b8-44f0-8219-22cacda89850";
  const app_uuid = context.auth.app_uuid;

  const { checkPermission, auth } = useAuth();

  console.log("aaaaaa: ", auth.access.settings["MA"]);

  return (
    <>
      <AppHeaderComponent
        context={context}
        name="settings"
        theme={context.theme}
      />
      <div className="page-section" style={{ marginTop: 130 }}>
        <ul className="settings-menu">
          {true ? (
            <li>
              <Link to="/settings/roles">
                <IoIosPeople size={30} />
                User Permissions
                <span className="next-arrow">
                  <IoIosArrowRoundForward size={30} />
                </span>
              </Link>
            </li>
          ) : null}
          {checkPermission("read_appearance_admin") ? (
            <li>
              <Link to="/settings/appearance">
                <IoIosColorPalette size={30} />
                Appearance
                <span className="next-arrow">
                  <IoIosArrowRoundForward size={30} />
                </span>
              </Link>
            </li>
          ) : null}
          {checkPermission("read_mail_admin") ? (
            <li>
              <Link to="/settings/mail">
                <MailIcon size={30} />
                Mail
                <span className="next-arrow">
                  <IoIosArrowRoundForward size={30} />
                </span>
              </Link>
            </li>
          ) : null}
          {app_uuid === sandboxAppUuid && (
            <li>
              <Link to="/settings/action-types">
                <MailIcon size={30} />
                Result Types
                <span className="next-arrow">
                  <IoIosArrowRoundForward size={30} />
                </span>
              </Link>
            </li>
          )}
          {checkPermission("read_modulesettings_admin") ? (
            <li>
              <Link to="/settings/module-settings">
                <TuneIcon size={30} />
                Module Settings
                <span className="next-arrow">
                  <IoIosArrowRoundForward size={30} />
                </span>
              </Link>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@mui/material";

import {
  TenantServiceApi,
  AppHeaderComponent,
  ApiLoaderComponent,
  SnackNotificationComponent,
  useAuth,
} from "@unity/components";
import Loading from "../../common/Loading";

export const ActionsPage = ({ context }) => {
  const { module_alias, roleId } = useParams();
  const { checkPermission } = useAuth();

  const [actions, setActions] = useState(null);
  const [loading, setLoading] = useState({ status: false, data: "" });
  const [message, setMessage] = useState({
    open: false,
    message: "",
    success: "",
  });

  useEffect(() => {
    const getData = async () => {
      const res = await TenantServiceApi.getResourceActionsByModule({
        module_alias,
        roles: [Number(roleId)],
        app_uuid: context.auth.app_uuid,
        use_id: true,
      });
      if (res) {
        setActions(res.data);
      }
    };
    getData();
  }, [roleId, module_alias]);

  const handleCloseSnackbar = () => {
    setMessage({ open: false, message: "", success: "" });
  };

  const updateAllow = (e) => {
    const localActions = actions.map((el) =>
      el.name === e.target.name ? { ...el, allow: e.target.checked } : el
    );
    setActions(localActions);
  };

  const updateName = (e) => {
    const localActions = actions.map((el) =>
      el.name === e.target.name ? { ...el, name: e.target.value } : el
    );
    setActions(localActions);
  };

  const checkAll = () => {
    let localActions = [...actions];
    if (actions.every((el) => el.allow)) {
      setActions(localActions.map((el) => ({ ...el, allow: false })));
    } else {
      setActions(localActions.map((el) => ({ ...el, allow: true })));
    }
  };

  const saveActions = async () => {
    setLoading({ status: true, data: "Saving Actions please wait..." });
    const promises = actions.map((el) => {
      const obj = {
        allow: el.allow,
        name: el.name,
      };
      return TenantServiceApi.updateResourceActions(el.id, obj);
    });
    Promise.all(promises).then((res) => {
      setLoading({ status: false, data: "" });
      if (res.every((el) => el && el.success)) {
        setMessage({ open: true, message: "updated actions", success: true });
      } else {
        setMessage({
          open: true,
          message: "There was an error with your request",
          success: false,
        });
      }
    });
  };

  return actions === null ? (
    <Loading />
  ) : (
    <>
      <ApiLoaderComponent status={loading.status} data={loading.data} />
      <SnackNotificationComponent onClose={handleCloseSnackbar} {...message} />
      <AppHeaderComponent
        name="settings"
        saveBtn={
          true || checkPermission("update_action_admin") ? saveActions : null
        }
        theme={context.theme}
        context={context}
      />
      {actions && Array.isArray(actions) && actions.length > 0 ? (
        <TableContainer sx={{ mt: 10 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>Allow</Typography>
                    <Checkbox
                      checked={actions.every((el) => el.allow)}
                      onChange={checkAll}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {actions.map((el) => (
                <TableRow key={el.id}>
                  <TableCell>
                    <TextField
                      name={el.name}
                      value={el.name}
                      onChange={updateName}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      name={el.name}
                      checked={el.allow}
                      onChange={updateAllow}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography sx={{ mt: 10 }}>
          There are currently no actions available for this permission
        </Typography>
      )}
    </>
  );
};

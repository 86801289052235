import React from "react";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { AuthProvider } from "@unity/components";
import Routes from "./routes";
import config from "./config";

export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthProvider module_alias={config.resourceName} {...props}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Routes context={context} history={history} />
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthProvider>
  );
}

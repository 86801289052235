import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import Add from "@mui/icons-material/Add";

import {
  AppHeaderComponent,
  SnackNotificationComponent,
  TenantServiceApi,
  ApiLoaderComponent,
} from "@unity/components";

export default function ResourceActionCreate({ context, route }) {
  const history = useHistory();

  const [hardRoles, setHardRoles] = useState([]);
  const [actions, setActions] = useState(["default action"]);
  const [module, setModule] = useState("");
  const [message, setMessage] = useState({
    open: false,
    message: "",
    success: "",
  });
  const [loading, setLoading] = useState({ status: false, data: "" });
  const [open, setOpen] = useState(false);
  const [customActions, setCustomActions] = useState([]);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    const getRoles = async () => {
      const res = await TenantServiceApi.getHardRoles();
      if (res) {
        setHardRoles(res.data);
      }
    };
    getRoles();
  }, []);

  const handleCloseSnackbar = () => {
    setMessage({ open: false, message: "", success: "" });
  };

  const addAction = () => {
    let localActions = [...actions];
    localActions.push(`action name ${actions.length}`);
    setActions(localActions);
  };

  const removeAction = (i) => {
    let localActions = [...actions];
    localActions.splice(i, 1);
    setActions(localActions);
  };

  const updateAction = (val, i) => {
    let localActions = [...actions];
    localActions[i] = val.toLowerCase();
    setActions(localActions);
  };

  const addCompleteSet = () => {
    let localActions = [...actions];
    const crud = ["create", "read", "update", "delete", ...customActions];
    crud.forEach((action) => {
      hardRoles.forEach((role) => {
        resources.forEach((res) => {
          if (role && action && res)
            localActions.push(`${action}_${res}_${role.name}`);
        });
      });
    });
    setActions([...new Set(localActions)]);
  };

  const clearActions = () => {
    setActions(["default action"]);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setCustomActions([]);
    setResources([]);
  };

  const handleSetCustomActions = (e, i) => {
    let localActions = [...customActions];
    localActions[i] = e.target.value;
    setCustomActions(localActions);
  };

  const handleSetResources = (e, i) => {
    let localResources = [...resources];
    localResources[i] = e.target.value;
    setResources(localResources);
  };

  const handleSave = async () => {
    //call templateaction controller func to save, pass module_directory name? name of action.
    setLoading({ status: true, data: "Saving Actions please wait..." });
    const promises = actions.map((el) => {
      const obj = {
        directory: module,
        name: el.trim(),
        app: context.auth.app_uuid,
      };
      return TenantServiceApi.storeResourceAction(obj);
    });
    Promise.all(promises).then((res) => {
      setLoading({ status: false, data: "" });
      if (res && res.every((el) => el)) {
        setMessage({ open: true, message: "success", success: true });
        setActions(["default action"]);
        setModule("");
        setTimeout(() => {
          history.push("/settings/roles");
        }, 2000);
      } else {
        setMessage({
          open: true,
          message: "There was an error with your request",
          success: false,
        });
      }
    });
  };

  return (
    <>
      <AppHeaderComponent
        name="settings"
        theme={context.theme}
        context={context}
        saveBtn={handleSave}
      />
      <ApiLoaderComponent status={loading.status} data={loading.data} />
      <SnackNotificationComponent onClose={handleCloseSnackbar} {...message} />
      <Box sx={{ mt: 10, ml: 1, width: "50%" }}>
        <Stack spacing={1}>
          <Typography>Create New Actions</Typography>
          <Typography variant="subtitle2">
            When you create these action, both the template and action are
            created. Go to th erelavent module permisisons to set the actual
            action.
          </Typography>
          <FormControl sx={{ mt: 1 }}>
            <InputLabel id="for_module">Module</InputLabel>
            <Select
              labelId="for_module"
              label="Module"
              value={module}
              onChange={(e) => setModule(e.target.value)}
            >
              {Object.keys(context.auth.access).map((el) => (
                <MenuItem value={el}>{el}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="text"
              onClick={() => setOpen(true)}
              size="small"
              disabled={!module}
            >
              Generate
            </Button>
            <Button variant="text" size="small" onClick={clearActions}>
              clear actions
            </Button>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {actions.map((el, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <TextField
                      value={el}
                      onChange={(e) => updateAction(e.target.value, i)}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => removeAction(i)}
                      disabled={actions && actions.length === 1}
                    >
                      <Close />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell />
                <TableCell>
                  <IconButton onClick={addAction}>
                    <Add />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Box>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          <Stack spacing={1}>
            <Button
              onClick={() => setResources((prev) => [...prev, ""])}
              variant="contained"
              endIcon={<Add />}
            >
              Add resource
            </Button>
            {resources.map((el, i) => (
              <TextField
                name={el}
                value={el}
                onChange={(e) => handleSetResources(e, i)}
                helper="site,appearance,action,role,permission,mail,modulesettings,folder,file,calendar"
              />
            ))}
            <Button
              onClick={() => setCustomActions((prev) => [...prev, ""])}
              variant="contained"
              endIcon={<Add />}
            >
              Add custom action
            </Button>
            {customActions.map((el, i) => (
              <TextField
                name={el}
                value={el}
                onChange={(e) => handleSetCustomActions(e, i)}
                helper="create,read,update,delete,*deploy,*undeploy; *site only"
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={addCompleteSet} variant="contained">
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

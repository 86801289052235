import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Loading from "../../common/Loading";
import { AppHeaderComponent, MailerApi, useAuth } from "@unity/components";

export default function MailIndex({ route, context }) {
  const { checkPermission } = useAuth();

  const [form, setForm] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    await MailerApi.storeMailer(form);
  };

  const getMailSettings = async () => {
    const res = await MailerApi.getMailer();
    if (!res.data) {
      setForm({});
    } else {
      setForm(res.data);
    }
  };

  useEffect(() => {
    getMailSettings();
  }, []);
  if (form) {
    return (
      <>
        <AppHeaderComponent
          name="settings"
          subpage="mail"
          subpage2="edit"
          context={context}
          theme={context.theme}
          saveBtn={checkPermission("update_mail_admin") ? handleSave : null}
        />
        <div style={{ paddingTop: 120 }}>
          <div className="block">
            <div className="container-fluid">
              <div className="form-row">
                <div className="form-group col-lg-4">
                  <TextField
                    name="host"
                    label="Host"
                    fullWidth
                    onChange={(e) => handleChange(e)}
                    value={form.host}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <TextField
                    name="port"
                    label="Port"
                    value={form.port}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <TextField
                    name="encryption"
                    label="Encryption"
                    fullWidth
                    value={form.encryption}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-4">
                  <TextField
                    name="username"
                    label="Username"
                    fullWidth
                    value={form.username}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <TextField
                    name="password"
                    type="text"
                    label="Password"
                    value={form.password}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <TextField
                    name="timeout"
                    label="Timeout"
                    fullWidth
                    value={form.timeout}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-lg-4">
                  <TextField
                    name="auth_mode"
                    label="Authentication Mode"
                    value={form.auth_mode}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <TextField
                    name="from_address"
                    type="email"
                    label="From Address"
                    value={form.from_address}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <TextField
                    name="from_name"
                    label="From Name"
                    fullWidth
                    value={form.from_name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}

import React, { useEffect, useState } from "react";
import {
  IoIosPeople,
  IoIosArrowRoundForward,
  IoIosCheckmark,
} from "react-icons/io";
import { Link as RouterLink } from "react-router-dom";
import { TextField, Link, Typography } from "@mui/material";

import AppButton from "../../common/AppButton";
import Loading from "../../common/Loading";
import { AppHeaderComponent, PermissionsApi, useAuth } from "@unity/components";

export default function PermissionsRoles({ route, context, history }) {
  const { checkPermission, auth } = useAuth();

  const [addNew, setAddNew] = useState(false);
  const [roles, setRoles] = useState(false);

  const handleSubmit = async () => {
    const res = await PermissionsApi.saveRoles(addNew);
    setAddNew(false);
  };

  const handleChange = (e) => {
    setAddNew({ ...addNew, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const getRoles = async () => {
      const res = await PermissionsApi.getRoles();
      setRoles(res.data);
    };
    getRoles();
  }, []);

  if (roles) {
    return (
      <div>
        <AppHeaderComponent
          name="settings"
          theme={context.theme}
          context={context}
        />
        <div style={{ paddingTop: 140 }}>
          <div className="page-section">
            <ul className="settings-menu">
              {roles.map((item) => {
                return (
                  <li>
                    {auth.access.settings["MA"] ? (
                      <Link
                        component={RouterLink}
                        to={{
                          pathname: `/settings/roles/permissions/${item.id}`,
                        }}
                        underline="none"
                      >
                        <IoIosPeople size={30} />
                        {item.name}
                        <span className="next-arrow">
                          <IoIosArrowRoundForward size={30} />
                        </span>
                      </Link>
                    ) : (
                      <Typography>
                        <IoIosPeople size={30} />
                        {item.name}
                        <span className="next-arrow">
                          <IoIosArrowRoundForward size={30} />
                        </span>
                      </Typography>
                    )}
                  </li>
                );
              })}
              {addNew ? (
                <li>
                  <div>
                    <IoIosPeople size={30} />
                    <TextField
                      style={{ width: 300, color: "white!important" }}
                      name="name"
                      onChange={(e) => handleChange(e)}
                    ></TextField>
                    <TextField
                      style={{ width: 300, color: "white!important" }}
                      name="description"
                      onChange={(e) => handleChange(e)}
                    ></TextField>
                    <span className="next-arrow">
                      <IoIosCheckmark
                        size={30}
                        onClick={() => handleSubmit()}
                      />
                    </span>
                  </div>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
          {checkPermission("create_role_admin") ? (
            <RouterLink to={"/settings/roles/create"}>
              <AppButton
                text="add new role"
                btnIcon="add"
                stylename="primary"
              />
            </RouterLink>
          ) : null}
          {auth.access.settings["RA"] ? (
            <RouterLink to={"/settings/action/create"}>
              <AppButton
                text="add new action for module"
                btnIcon="add"
                stylename="primary"
                style={{ marginLeft: 10 }}
              />
            </RouterLink>
          ) : null}
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}

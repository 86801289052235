import React from "react";
import { Switch, Route } from "react-router-dom";

import { useAuth } from "@unity/components";
import Settings from "./pages";
import MailIndex from "./pages/mail";

import PermissionsCreate from "./pages/permissions/PermissionsCreate";
import PermissionsRoles from "./pages/permissions/PermissionsRoles";
import PermissionsPage from "./pages/permissions/PermissionsPage";
import ResourceActionCreate from "./pages/permissions/resourceActionCreate";

import CallResultTypes from "./pages/actions/index";
import Theme from "./pages/theme";

import ModuleSettingsIndex from "./pages/moduleSettings/index";
import ModuleSettingsCreate from "./pages/moduleSettings/create";
import ModuleSettingsEdit from "./pages/moduleSettings/edit";

import { ActionsPage } from "./pages/permissions/actionsPage";

export default function Routes({ context, history }) {
  const { auth, checkPermission } = useAuth();

  console.log("here ", auth);

  const settingsAccess = auth.access.settings["A"];

  return (
    <Switch location={location}>
      <Route path="/location" exact component={Location} />

      {settingsAccess && (
        <Route
          path="/settings/index"
          exact
          component={(route) => (
            <Settings route={route} context={context} history={history} />
          )}
        />
      )}
      {settingsAccess && checkPermission("read_appearance_admin") && (
        <Route
          path="/settings/appearance"
          exact
          component={(route) => (
            <Theme route={route} context={context} history={history} />
          )}
        />
      )}
      {settingsAccess && checkPermission("read_mail_admin") && (
        <Route
          path="/settings/mail"
          exact
          component={(route) => (
            <MailIndex route={route} context={context} history={history} />
          )}
        />
      )}
      {settingsAccess && (
        <Route
          path="/settings/roles/permissions/:id"
          exact
          component={(route) => (
            <PermissionsPage
              route={route}
              context={context}
              history={history}
            />
          )}
        />
      )}
      {settingsAccess && (
        <Route
          path="/settings/roles/:roleId/permissions/actions/:module_alias"
          exact
          component={(route) => (
            <ActionsPage route={route} context={context} history={history} />
          )}
        />
      )}
      {settingsAccess && (
        <Route
          path="/settings/action/create"
          exact
          component={(route) => (
            <ResourceActionCreate
              route={route}
              context={context}
              history={history}
            />
          )}
        />
      )}
      {settingsAccess && checkPermission("create_role_admin") && (
        <Route
          path="/settings/roles/create"
          exact
          component={(route) => (
            <PermissionsCreate
              route={route}
              context={context}
              history={history}
            />
          )}
        />
      )}
      {settingsAccess && (
        <Route
          path="/settings/roles"
          exact
          component={(route) => (
            <PermissionsRoles
              route={route}
              context={context}
              history={history}
            />
          )}
        />
      )}
      {settingsAccess && (
        <Route
          path="/settings/action-types"
          exact
          component={(route) => (
            <CallResultTypes
              route={route}
              context={context}
              history={history}
            />
          )}
        />
      )}
      {auth.access.settings["MA"] && (
        <>
          <Route
            path="/settings/module-settings"
            exact
            component={(route) => <ModuleSettingsIndex context={context} />}
          />
          <Route
            path="/settings/module-settings/create"
            exact
            component={(route) => <ModuleSettingsCreate context={context} />}
          />
          <Route
            path="/settings/module-settings/:id/edit"
            exact
            component={(route) => (
              <ModuleSettingsEdit context={context} route={route} />
            )}
          />
        </>
      )}
    </Switch>
  );
}

import React, { useState } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useHistory } from "react-router";

import ApiLoader from "../../common/ApiLoader";
import {
  AppHeaderComponent,
  PermissionsApi,
  TenantServiceApi,
} from "@unity/components";

export default function PermissionsCreate({ context }) {
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState({ status: false });
  const [tenantRole, setTenantRole] = useState(true);

  let history = useHistory();

  const handleChange = (e) => {
    const newData = { ...role };
    newData[e.target.name] = e.target.value;
    setRole(newData);
  };

  const handleSave = async () => {
    setLoading({
      status: true,
      data: "Validating Data, Please Wait....",
    });

    const res = await PermissionsApi.saveRoles(role);

    setLoading({ status: true, data: "Permissions Saved, Please Wait...." });

    setTimeout(() => {
      history.push("/settings/roles");
    }, 2000);
  };

  const saveRoleTenant = async () => {
    setLoading({
      status: true,
      data: "Validating Data, Please Wait....",
    });

    const res = await TenantServiceApi.storeRole({
      ...role,
      app: context.auth.app_uuid,
    });

    setLoading({ status: true, data: "Permissions Saved, Please Wait...." });

    setTimeout(() => {
      history.push("/settings/roles");
    }, 2000);
  };

  return (
    <div>
      <ApiLoader status={loading.status} data={loading.data} />
      <AppHeaderComponent
        name="settings"
        subpage="roles"
        subpage2="create"
        context={context}
        theme={context.theme}
        saveBtn={tenantRole ? saveRoleTenant : handleSave}
      />
      <div style={{ paddingTop: 120 }}>
        <div className="page-section padding-lg">
          <FormControlLabel
            control={
              <Checkbox
                checked={tenantRole}
                onChange={(e) => setTenantRole(e.target.checked)}
              />
            }
            label="Use tenant service"
          />
          <TextField
            label="Role Name"
            value={role.name}
            onChange={(e) => handleChange(e)}
            fullWidth={true}
            name="name"
            helperText={"Permission role name"}
          ></TextField>
          <TextField
            label="Role Description"
            value={role.description}
            onChange={(e) => handleChange(e)}
            fullWidth={true}
            name="description"
            helperText={"Permission role name"}
          ></TextField>
        </div>
      </div>
    </div>
  );
}
